/**
 * Created by YFSZ on 20190825.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL (url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail (email) {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString (str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray (arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
/**
 * @param {*} phone
 */
export function validPhone (phone) {
  // const reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9]|19[0|1|3|5|8|9 ])\d{8}$/
  // const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
  const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[6]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return reg.test(phone)
}

/**
 *身份证
 * @param card
 * @returns {boolean}
 */
export function validCard (card) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return !reg.test(card)
}

/** 编号 正则
 * @param {*} BusinessNum
 */
export function validBusinessNum (str) {
  const reg = /^[a-zA-Z0-9-]*$/
  return reg.test(str)
}
// 公共验证 业务编号
export function validbusinessNo (r, v, c) {
  if (v) {
    if (!validBusinessNum(v)) {
      c(new Error('请输入正确的编号'))
    } else {
      c()
    }
  } else {
    c()
  }
}
// 公共校验 期限选择器 期限必填校验
export const validatorRequiredTerm = (validTimeUnit) => {
  return (rule, value, callback) => {
    console.log(value, validTimeUnit, 'validTimeUnit')
    if (!value) {
      callback(new Error('请输入日期'))
    } else if (value && !validTimeUnit) {
      callback(new Error('请选择日期格式'))
    } else if (!value && validTimeUnit) {
      callback(new Error('请输入日期'))
    } else if (value && validTimeUnit === 'YEAR') {
      if (value * 1 > 3) {
        callback(new Error('选择年,日期大于0小于等于3'))
        console.log('选择年,日期大于0小于等于3')
      } else {
        callback()
      }
    } else if (value && validTimeUnit === 'MONTH') {
      if (value * 1 > 12) {
        callback(new Error('选择月,日期大于0小于等于12'))
        console.log('选择月,日期大于0小于等于12')
      } else {
        callback()
      }
    } else if (validTimeUnit === 'DAY') {
      if (value * 1 > 365) {
        callback(new Error('选择日,日期大于0小于等于365'))
        console.log('选择日,日期大于0小于等于365')
      } else {
        callback()
      }
    }
  }
}

// 公告校验 期限选择器 关系校验
export const validateTerm = (validTimeUnit) => {
  return (rule, value, callback) => {
    if (value) {
      if (validTimeUnit === 'YEAR') {
        if (value > 3) {
          callback(new Error('选择年,日期大于0小于等于3'))
        } else {
          callback()
        }
      } else if (validTimeUnit === 'MONTH') {
        if (value > 12) {
          callback(new Error('选择月,日期大于0小于等于12'))
        } else {
          callback()
        }
      } else if (validTimeUnit === 'DAY') {
        if (value > 365) {
          callback(new Error('选择日,日期大于0小于等于365'))
        } else {
          callback()
        }
      }
    } else {
      callback()
    }
  }
}
