var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-form',{ref:"fromdata",staticClass:"formStyle",attrs:{"componentList":_vm.fromConfig,"formAttrs":{
        model: _vm.queryParas,
        labelWidth: '90px',
      },"type":_vm.type},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}},[_c('div',{attrs:{"slot":"btn_after"},slot:"btn_after"},[_c('base-button',{staticClass:"foldBtn",class:{PutawayBtn:_vm.fold},attrs:{"icon":"iconfont iconzhankai","type":"default"},on:{"click":function($event){_vm.fold=!_vm.fold}}})],1)]),_c('base-table',{ref:"tableData",staticClass:"main-page-table",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
        data: _vm.tableData,
        stripe: true,
      },"type":_vm.type,"api":_vm.api,"getApi":'getPage',"loadCount":_vm.loadCount,"queryParas":_vm.queryParas,"dataSource":_vm.tableData,"webPage":false},on:{"update:queryParas":function($event){_vm.queryParas=$event},"update:query-paras":function($event){_vm.queryParas=$event},"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event}},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"action",fn:function(scope){return [(scope.row.reviewStatus === 10||scope.row.reviewStatus === 20||scope.row.reviewStatus === 30)?_c('icon-button',{attrs:{"content":"查看","icon":"iconfont iconchakan"},on:{"click":function($event){return _vm.info(scope.row)}}}):_vm._e(),((scope.row.reviewStatus === 40||scope.row.reviewStatus === 50||scope.row.fileMakeStatus=== 10 || scope.row.fileMakeStatus=== 20) && scope.row.reviewStatus !==91
          )?_c('icon-button',{attrs:{"content":"编辑","icon":"iconfont iconbianji3"},on:{"click":function($event){$event.preventDefault();return _vm.jump('/business/essentialFactor/elementProduction', scope.row)}}}):_vm._e()]}}])}),_c('back-dialog',{attrs:{"visible":_vm.visible,"queryTaskResultDTO":_vm.queryTaskResultDTO,"taskId":_vm.taskId},on:{"update:visible":function($event){_vm.visible=$event},"handleFilter":_vm.handleFilter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }