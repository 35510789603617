let baseId = 1
export const getPositionTop = node => {
  // 获取元素到顶部距离-通用方法
  var top = node.offsetTop
  var parent = node.offsetParent
  while (parent != null) {
    top += parent.offsetTop
    parent = parent.offsetParent
  }
  return top // 所有的父元素top和
}

export function throttle (func, wait = 200) {
  let lastTime
  return function (...rest) {
    if (!lastTime || new Date().getTime() - lastTime > wait) {
      lastTime = +new Date()
      func.apply(this, rest)
    }
  }
}

export const getTableId = () => {
  return baseId++
}
