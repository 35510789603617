import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class company extends Rest {
  /*
   *提交  修改新增
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  saveall (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveall`,
      data
    })
  }

  /*
   *申请主体
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  drop () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/drop`
    })
  }

  /*
   *获取访谈纪要统计分页列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getStatisticsPage (params) {
    return http({
      method: 'get',
      url: '/api/company/interview/statisticsPage',
      params
    })
  }

  /*
   *获取访谈纪要统计分页列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getCompanyByKeyId (params) {
    return http({
      method: 'get',
      url: '/api/company/supplier/getCompanyByKeyId',
      params
    })
  }

  /*
   *获取访谈纪要访谈记录分页列表
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getInterviewPage (params) {
    return http({
      method: 'get',
      url: '/api/company/interview/page',
      params
    })
  }

  /*
   *新增访谈纪要评论
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  addComments (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/company/interview/addComments',
      data
    })
  }

  /*
   *新增/修改访谈纪要记录
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  addInterviewSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/company/interview/save',
      data
    })
  }

  /*
   *获取访谈纪要暂存信息
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  getInterviewDraftInfo (params) {
    return http({
      method: 'get',
      url: '/api/company/interview/getInterviewDraftInfo',
      params
    })
  }

  /*
   *申请主体222
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  listbylabel (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/listbylabel`,
      params
    })
  }

  /**
   * 获取供应商
   * @returns {AxiosPromise}
   */
  getSupplier () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`
    })
  }

  /**
   * 查看文件
   * @param params
   * @returns {AxiosPromise}
   */
  getsupFileList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/cfgitem`,
      params
    })
  }

  /**
   * 查看文件履历
   * @param
   * @returns {AxiosPromise}
   */
  getsupRecordList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/record`,
      params
    })
  }

  /**
   * 删除文件履历
   * @param
   * @returns {AxiosPromise}
   */
  delsupRecordList (params) {
    return http({
      showLoading: true,
      method: 'DELETE',
      url: `/api/${this.subSys}/${this.module}/del`,
      params
    })
  }

  /**
   *文件上传成功后将旧文件保存到文件履历
   * @param data
   * @returns {AxiosPromise}
   */
  saveFileold (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/upload`,
      data
    })
  }

  /**
   * 账号分页
   * @param params
   * @returns {AxiosPromise}
   * @constructor
   */
  Accountpage (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/account/page`,
      params
    })
  }

  /**
   * 人员分页
   * @param params
   * @returns {AxiosPromise}
   * @constructor
   */
  Personnelpage (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/personnel/page`,
      params
    })
  }

  /*
   *天眼查获取
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  tyceas (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/tyceas`,
      params
    })
  }

  /*
   *上传授权书
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  uploadAuthorization (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/manage/upload`,
      data
    })
  }

  /*
   *上传授权书
   * @data data 请求参数
   * @returns {AxiosPromise}
   */
  changeEnable (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/modifyEnableStatus`,
      data
    })
  }

  /*
   *删除签章人
   * @param params 请求参数
   * @returns {AxiosPromise}
   */
  delSealAdmin (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/delSealAdmin`,
      params
    })
  }

  // 获取银行
  getBankList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getBankList`,
      params
    })
  }

  // 添加供应商账号
  AccountAdd (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/account/add`,
      data
    })
  }

  // 签章人详情
  getSignerDetails (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getByIds`,
      params
    })
  }

  // 新增签章人
  addSigner (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/storagePersonnel`,
      data
    })
  }

  // 提交个人认证
  submitPersonnel (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/person/commit`,
      data
    })
  }

  // 提交企业认证
  submitEnterprise (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/org/commit`,
      data
    })
  }

  // 根据：organizationId 获取用户信息
  getEnterpriseInfo (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/companyList`,
      params
    })
  }

  // 二维码状态
  getQrCodeStutas (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getStatus`,
      params
    })
  }

  // 查询企业认证详情
  getEnterpriseStutas (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/org/getById`,
      params
    })
  }

  // 小额打款次数
  getVerficationNum (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/org/getVerficationNum`,
      params
    })
  }

  // 校验金额
  confirmAmount (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/org/confirmAmount`,
      params
    })
  }

  // 重启请求打款
  rePayment (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/rePayment`,
      params
    })
  }

  // 重新生成二维码
  refreshQrCode (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/person/refreshQrCode`,
      params
    })
  }

  // 查询远端认证状态
  checkRemoteOrgCertifyStatus (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/checkRemoteOrgCertifyStatus`,
      params
    })
  }

  // 远端认证状态
  saveCertifyBankAndRegisterSealAdmin (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/org/saveCertifyBankAndRegisterSealAdmin`,
      data
    })
  }

  // 页面新增签章人
  addOrgSealAdmin (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/addOrgSealAdmin`,
      data
    })
  }

  // 业务状态
  bizStaus (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/person/getById`,
      params
    })
  }

  // 认证管理 平台端数据
  platformTableeData (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/platform/page`,
      params
    })
  }

  // 认证管理 供应商端数据
  supplierTableeData (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/supplier/page`,
      params
    })
  }

  // 供应商加/解锁 修改锁定状态
  updateSupplierLock (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/update/lockstatus`,
      data
    })
  }

  // 印章文件获取
  getSealImage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSealImage`,
      params
    })
  }
  // 获取供应商附件履历

  getFileList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getFileList`,
      params
    })
  }

  // 查询签章人
  getSealAdminDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSealAdminDetail`,
      params
    })
  }

  // 导入财务信息
  importFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/import`,
      data
    })
  }

  // 导入财务信息
  getAnalysisList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  // 获取诉讼信息
  getLitigationList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  // 同步天眼查信息
  getSynchronization (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/synchronization`,
      params
    })
  }

  // 新增财务信息
  analysisAdd (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/add`,
      data
    })
  }

  // 信息增改
  saveOrUpdate (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdate`,
      data
    })
  }

  // 删除
  remove (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/remove`,
      params
    })
  }

  // 同步天眼查
  synchronousTyc (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/synchronousTyc`,
      data
    })
  }

  // 同步天眼查股权结构
  synchronousTycGqjg (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/synchronousTycGqjg`,
      params
    })
  }

  // 查询舆情情况数据
  opinionPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/company/opinion/page',
      params
    })
  }

  // 上传excel接口
  opinionFileExcel (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/company/opinion/fileExcel',
      data
    })
  }

  // 企业资产信息增改
  batchSubmitAsset (data, id) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSubmitAsset?enterpriseId=${id}`,
      data
    })
  }

  // 企业资产信息查询
  getAssetListByEnterpriseId (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getAssetListByEnterpriseId`,
      params
    })
  }

  // 企业负债信息增改
  batchSubmitDebt (data, id) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSubmitDebt?enterpriseId=${id}`,
      data
    })
  }

  // 企业负债信息查询
  getDebtListByEnterpriseId (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getDebtListByEnterpriseId`,
      params
    })
  }

  // 企业外部地产增改
  batchSubmitEstate (data, id) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSubmitEstate?enterpriseId=${id}`,
      data
    })
  }

  // 企业外部地产查询
  getExtEstateListByEnterpriseId (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getExtEstateListByEnterpriseId`,
      params
    })
  }
}
