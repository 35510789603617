<template>
  <el-radio-group v-bind="$attrs" v-on="$listeners">
      <el-radio v-for="v in localOptions"
               :key="v[optionKey]"
               :label="v[optionKey]"
               :disabled="disabled">
               {{v[optionValue]}}
      </el-radio>
    </el-radio-group>
</template>

<script>
import { getDictLists } from '@/filters/fromDict'
export default {
  name: 'operateRadio',
  props: {
    options: [],
    selectedField: {
      type: Array,
      default: () => ['value', 'label']
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    // 字典code
    dictTypeCode: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    optionKey () {
      if (this.dictTypeCode !== '') {
        return 'dictId'
      }
      return this.selectedField[0] || 'value'
    },
    optionValue () {
      if (this.dictTypeCode !== '') {
        return 'dictName'
      }
      return this.selectedField[1] || 'label'
    },
    localOptions () {
      if (this.dictTypeCode !== '') {
        return getDictLists(this.dictTypeCode) || []
      }
      return this.options
    }
  }
}
</script>
