import BaseSelect from '@/components/common/base-select/base-select'
import { getDictLists } from '@/filters/fromDict'
import { validbusinessNo } from '@/utils/validate'
import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
export const fromConfig = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        maxlength: '20',
        placeholder: '请输入'
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      tag: SupplierSelect,
      attrs: {
        placeholder: '请选择',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      }
    },

    {
      label: '审核状态',
      prop: 'reviewStatus',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: getDictLists('FILE_MAKE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.fold
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px',
      minWidth: '120px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true,
      minWidth: '120px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      minWidth: '120px'
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      minWidth: '120px',
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      'show-overflow-tooltip': true,
      minWidth: '120px',
      formatter: row => {
        return (row.reviewStatusName ? row.reviewStatusName : '--')
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      minWidth: '200px'
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right',
      minWidth: '120px'
    }
  ]
}
