import BaseSelect from '@/components/common/base-select/base-select.vue'
import upload from './upload.vue'
// import { getDictLists } from '@/filters/fromDict'
import Storage from '@/utils/storage'
import { formatDate } from '@/utils/auth/common'
import operateRadio from './operateRadio.vue'

export const formConfigListx = (content) => {
  // 返回修改、拒绝，【审批意见】为必填
  const flag = content.formdata.reviewStatus === 40 || content.formdata.reviewStatus === 30

  // 是否显示回退流程  【审核环节】为要素落实-&评审会批复落实   且审核意见为返回修改时候显示 --2022/5/18 岳沟通需求后得出,都会有返回结点
  const isShowBackToProcess = content.formdata.reviewStatus === 20 && (content.processStatus === 'ELEMENT_IMPLEMENTATION' || content.processStatus === 'SUPPLEMENT')

  // 是否显示分配风控经理  角色为运营岗时 且在融资申请环节》显示后会禁用审核结果且选中通过
  const showRiskManager = Storage.getSession('examine').finBusuinessInfoVO.processNodeName === '分配风控' && content.processStatus === 'ADMIT'
  // 改为 审核节点来判断 2022/5/25 冯婷婷，岳成才沟通结果
  // const isShowInput = Storage.getSession('examine').finBusuinessInfoVO.processNodeName==='分配风控'
  // 融资申请(尽调) 无返回节点
  // const optionsData = () => {
  //   if (content.processStatus === 'ADMIT') {
  //     return [
  //       { id: 10, name: '审核通过' },
  //       { id: 30, name: '拒绝' }
  //     ]
  //   } else {
  //     return [
  //       { id: 10, name: '审核通过' },
  //       { id: 40, name: '返回修改' },
  //       { id: 30, name: '拒绝' }
  //     ]
  //   }
  // }
  // 2022/07/29客户反馈版本，又统一为
  // const optionsData = () => {
  //   return [
  //     { id: 10, name: '审核通过' },
  //     { id: 40, name: '返回修改' },
  //     { id: 30, name: '拒绝' }
  //   ]
  // }
  // 审核结果是否显示返回修改  要素落实且角色为风控 oa才能拒绝, 放款审核岗不能拒绝 。 2022/8/5 冯婷婷bug 反馈编号 775436013324472320
  // 自定义签章 没有拒绝
  function isRefuseBtn () {
    const data = Storage.getSession('examine').finBusuinessInfoVO
    return ((content.processStatus === 'ELEMENT_IMPLEMENTATION' && data.processNodeName === '放款岗审核') || (data.batchNum > 0))
  }
  const optionsData = () => {
    const data = Storage.getSession('examine')
    if (data?.completeType === '1') { // 直送回来的只有通过
      return [
        { id: 10, name: '审核通过' }
      ]
    } else if (isRefuseBtn()) {
      return [
        { id: 10, name: '审核通过' },
        { id: 40, name: '返回修改' }
      ]
    } else {
      return [
        { id: 10, name: '审核通过' },
        { id: 40, name: '返回修改' }
        // { id: 30, name: '拒绝' }
      ]
    }
  }

  const methodsOptions = !!content.isSpecial && content.formdata.reviewStatus === 40 ? [
    // {
    //   value: '40',
    //   label: '直送'
    // },
    {
      value: '20',
      label: '退回'
    }
  ] : [
    {
      value: '40',
      label: '直送'
    },
    {
      value: '20',
      label: '退回'
    }
  ]
  return [
    {
      label: '分配风控经理',
      prop: 'riskManagerId',
      rules: [{ required: true, message: '选择风控经理', trigger: 'change' }],
      span: 24,
      attrs: {
        placeholder: '请选择',
        options: content.riskManagerOptions,
        selectedField: ['riskManagerId', 'riskManagerName']
      },
      isHidden: !showRiskManager,
      tag: BaseSelect
    },
    {
      label: '审核结果',
      prop: 'reviewStatus',
      rules: [{ required: true, message: '请选择审核结果', trigger: 'change' }],
      span: 18,
      attrs: {
        placeholder: '请选择',
        options: optionsData(),
        disabled: showRiskManager
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeReviewStatus(data)
        }
      }
    },
    {
      label: '附件',
      prop: 'uploadFileId',
      span: 6,
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        label: '上传附件',
        icon: 'iconfont iconshangchuan',
        btnType: 'upload',
        accept: '.jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx',
        'http-request': (param) => {
          content.uploadFile(param)
        }
      },
      on: {
        inputSuccess: content.inputSuccess
      },
      tag: upload
    },
    {
      label: '',
      prop: 'radio',
      span: 18,
      tag: operateRadio,
      rules: [{ required: true, message: '请选择', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: methodsOptions
      },
      isHidden: !isShowBackToProcess && !content.backToProcessOptions.length > 0
    },
    // {
    //   label: '回退至流程',
    //   prop: 'backToProcess',
    //   rules: [{ required: false, message: '请输入', trigger: 'blur' }],
    //   span: 18,
    //   attrs: {
    //     placeholder: '请选择',
    //     options: getDictLists('BACK_TO_PROCESS_STATUS'),
    //     selectedField: ['dictId', 'dictName']
    //   },
    //   isHidden: !isShowBackToProcess,
    //   tag: BaseSelect
    // },
    {
      label: '选择退回节点',
      prop: 'taskDefinitionKey', // 字段改变 旧：backToProcess
      rules: [{ required: true, message: '请选择回退节点', trigger: 'change' }],
      span: 18,
      attrs: {
        placeholder: '请选择',
        options: content.backToProcessOptions,
        selectedField: ['id', 'name']
      },
      isHidden: !isShowBackToProcess && !content.backToProcessOptions.length > 0,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeBackToProcess(data)
        }
      }
    },
    // {
    //   label: '办理人',
    //   prop: 'assignee',
    //   rules: [{ required: true, message: '请选择办理人', trigger: 'change' }],
    //   span: 18,
    //   attrs: {
    //     placeholder: '请选择',
    //     options: content.assigneeOptions,
    //     selectedField: ['id', 'name']
    //   },
    //   tag: BaseSelect,
    //   isHidden: !isShowBackToProcess && !content.backToProcessOptions.length > 0
    // },
    {
      label: '审核意见',
      prop: 'reviewOpinion',
      rules: [flag ? { required: true, message: '请输入审核意见', trigger: 'blur' } : ''],
      attrs: {
        placeholder: content.processStatus === 'ADMIT' && Storage.getSession('examine').finBusuinessInfoVO.processNodeName === '风控受理' ? '是否受理，并对于需增加进放款前要素落实或贷后管理的文件出具补充意见。' : '请输入',
        type: 'textarea',
        maxlength: '1000',
        row: 10
      },
      span: 24
    }
  ]
}
export const nodForm = (content) => {
  return [
    {
      label: '选择退回节点',
      prop: 'reviewStatus',
      rules: [{ required: true, message: '请选择选择退回节点', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.options,
        selectedField: ['taskDefinitionKey', 'taskDefinitionName']
      },
      tag: BaseSelect,
      span: 24,
      on: {
        change (data) {
          content.changeReviewStatus(data)
        }
      }

    }

  ]
}
export const fundForm = (content) => {
  return [
    {
      label: '资金方',
      prop: 'capitalSideId',
      rules: [{ required: true, message: '请选择选择资金方', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName']
      },
      tag: BaseSelect,
      span: 24,
      on: {

      }

    }

  ]
}
// 文件履历查询
export const docHistoryFormConfig = (content) => {
  const span = 6
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '文件类型名称',
      prop: 'documentId',
      attrs: {
        clearable: true,
        placeholder: '请输入',
        options: content.fileTypeLists,
        selectedField: ['documentId', 'documentName']
      },
      span,
      tag: BaseSelect
    },
    {
      label: '操作人',
      prop: 'operatorName',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '操作日期区间',
      prop: 'operateTime',
      isHidden: content.fold,
      attrs: {
        type: 'daterange',
        rangeSeparator: '至',
        'value-format': 'timestamp',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期'
      },
      tag: 'el-date-picker',
      on: {
        change: (data) => {
          // content.queryParas.operateTimeEnd = formatDate(data[1], 'YY-MM-DD')
          // content.queryParas.operateTimeStart = formatDate(data[0], 'YY-MM-DD')
          content.queryParas.operateTimeEnd = data[1]
          content.queryParas.operateTimeStart = data[0]
        }
      },
      span
    }
  ]
}

// 文件履历列表
export const docHistoryTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      HiddenOverflow: false
    },
    {
      label: '文件类型名称',
      prop: 'documentName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '操作人',
      prop: 'operatorName',
      HiddenOverflow: false,
      maxlength: '500'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      HiddenOverflow: false,
      maxlength: '500',
      formatter: row => {
        return formatDate(row.operateTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '操作类型',
      prop: 'operateType',
      HiddenOverflow: false,
      formatter: row => {
        const [obj] = content.operateTypeOptions.filter((item) => item.dictId === row.operateType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '90',
      HiddenOverflow: false,
      align: 'left'
    }
  ]
}
