<script>
import { throttle, getPositionTop, getTableId } from '../helper'

const defaultColumnAttrs = {
  align: 'center'
}

const defaultTableAttrs = {
  'cell-class-name': 'sz-table-cell',
  stripe: true,
  'header-cell-class-name': 'sz-table-header'
}
/** 处理表格序号的宽度问题 */
const formatIndexColumn = (it) => {
  if (it.label === '序号') {
    return {
      ...it,
      width: '50px'
    }
  } else {
    return it
  }
}
const formatterAttrs = (columns) => {
  const result = []
  columns.forEach((it) => {
    if (it.isHidden) {
      return
    }

    it = formatIndexColumn(it)

    // 添加内容宽度溢出显示为省略号
    if (it.HiddenOverflow) {
      it.showOverflowTooltip = false
    } else {
      it.showOverflowTooltip = true
    }
    it.resizable = false
    if (it.childs) {
      it.childs = formatterAttrs(it.childs)
    }
    // 写死居中
    result.push({ ...it, ...defaultColumnAttrs })
  })
  return result
}

const defaultPagination = {
  'page-size': 10,
  'pager-count': 5,
  layout: 'total, sizes, prev, pager, next, jumper',
  'page-sizes': [10, 20, 30, 40, 50, 100]
}

export const initStyle = (dom) => {
  const idName = 'data-table'
  const el = dom || window.document.querySelector(`#${idName}`)
  const elTop = getPositionTop(el)
  const win = document.body.clientHeight || document.documentElement.clientHeight
  return Math.max(win - elTop, 100)
}

const defaultPageConfig = {
  pageIndex: 1,
  pageSize: 10
}

export default {
  name: 'baseTable',

  props: {
    pageKeys: {
      type: Array,
      default: () => ['pageIndex', 'pageSize']
    },
    // 表格属性
    tableAttrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    spanMethod: {},
    // 是否用户传入数据自定义分页
    webPage: {
      type: Boolean,
      default: true
    },
    selfChangeHeight: {
      type: Number
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    showPage: {
      type: Boolean,
      default: true
    },
    paginationConfig: {
      type: Object,
      default: () => ({})
    },
    queryParas: {
      type: Object,
      default: () => ({})
    },
    // 列配置项
    columns: {
      type: Array,
      default: () => []
    },
    loadCount: {
      type: Number,
      default: 1
    },
    api: {
      default: null
    },
    getApi: {
      type: String,
      default: 'getList'
    },
    isCaculateHeight: {
      type: Boolean,
      default: true
    },
    staticData: {
      type: Boolean,
      default: false
    },
    // 表格渲染字段
    tableKey: {
      type: String,
      default: ''
    },
    // 总条数渲染字段
    totalKey: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      total: 0,
      tableHeight: 0,
      defaultPageConfig,
      tableMaxHeight: 0,
      defaultTableAttrs,
      sourceWatcher: null,
      tableDatas: [],
      selectionData: [],
      resizeEvent: null
    }
  },
  computed: {
    borderBottom () {
      return !!this.tableAttrs.border
    },
    normalColumns () {
      return formatterAttrs(this.columns)
    },
    normalTableAttrs () {
      return { ...this.defaultTableAttrs, ...this.tableAttrs }
    },
    currentQueryParas: {
      get () {
        // const [pageIndexKey, pageSizeKey] = this.pageKeys
        return { ...this.defaultPageConfig, ...this.queryParas }
      },
      set (val) {
        this.$emit('update:queryParas', val)
      }
    }
  },
  watch: {
    loadCount () {
      this.$nextTick(() => {
        this.loadData()
      })
    },
    webPage: {
      // 控制执行分页数据处理
      handler (val) {
        if (val) {
          this.sourceWatcher = this.$watch(
            'dataSource',
            function () {
              this.$nextTick(() => {
                this.loadData()
              })
            },
            {
              deep: true,
              immediate: true
            }
          )
        } else {
          this.sourceWatcher = null
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.isCaculateHeight) {
      this.registerCalculate()
      this.$nextTick(() => {
        this.calculateHeight()
      })
    }
  },
  methods: {
    doLayout () {
      this.$refs.sztable.doLayout()
    },
    toggleRowSelection (...arg) {
      return this.$refs.sztable.toggleRowSelection(...arg)
    },
    clearSelection () {
      return this.$refs.sztable.clearSelection()
    },
    // 添加响应式事件
    registerCalculate () {
      this.resizeEvent = throttle(this.calculateHeight)
      window.addEventListener('resize', this.resizeEvent)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.resizeEvent)
      })
    },

    calculateHeight () {
      const { selfChangeHeight = 60 } = this
      if (!this.$refs.sztable) {
        return
      }
      const height = initStyle(this.$refs.sztable.$el)
      this.tableHeight = this.tableMaxHeight = Math.max(
        height - selfChangeHeight,
        190
      )
    },

    selectionChange (selection) {
      this.selectionData = selection
      this.$emit('update:selectedRows', selection)
      this.$emit('selectRowChange', selection)
    },

    buildPaginationTableData (tableDatasAll) {
      // 构造分页数据
      const _this = this
      _this.tableDatas = []
      const startIndex =
        (this.queryParas.pageIndex - 1) * this.queryParas.pageSize
      let endIndex = startIndex + this.queryParas.pageSize - 1
      endIndex =
        endIndex >= tableDatasAll.length ? tableDatasAll.length - 1 : endIndex
      _this.tableDatas = tableDatasAll.slice(startIndex, endIndex + 1)
    },

    buildDataSorce () {
      // 构建表格数据
      const {
        dataSource,
        currentQueryParas: { pageIndex, pageSize }
      } = this

      if (!this.showPage) {
        this.tableDatas = dataSource
        return
      }
      const total = dataSource.length
      const startIndex = (pageIndex - 1) * pageSize
      // 如果超出部分就直接去数据的长度
      const endIndex =
        startIndex + pageSize - 1 > total ? total : startIndex + pageSize - 1

      this.tableDatas = dataSource.slice(startIndex, endIndex + 1)
      if (!this.tableDatas?.length && pageIndex > 1) {
        this.$set(this.currentQueryParas, 'pageIndex', pageIndex - 1)
        this.buildDataSorce()
      }
      this.total = total
    },

    async loadData () {
      if (this.webPage) {
        return this.buildDataSorce()
      }
      if (!this.api) {
        return console.warn('请传入请求接口函数')
      }
      try {
        const { data } = await this.api[this.getApi](this.currentQueryParas)
        if (this.showPage) {
          this.tableDatas = this.tableKey ? data[this.tableKey] : (data.datas ? data.datas : data.records)
          this.total = this.totalKey ? data[this.totalKey] : (data.totalRow ? data.totalRow : data.total)
          if (!this.tableDatas.length) {
            this.total = 0
          }
        } else {
          if (typeof this.spanMethod === 'function') {
            this.tableDatas = this.spanMethod(data)
          } else {
            this.tableDatas = data
          }
        }
        this.$emit('update:dataSource', this.tableDatas)
        this.$emit('loadSuccess', true)
      } catch (error) {
        this.$emit('loadSuccess', false)
      }
    },

    pageIndexChange (index) {
      const [indexKey] = this.pageKeys
      if (this.webPage) {
        this.$emit('pageIndexChange', index)
        this.staticData && this.$set(this.currentQueryParas, 'pageIndex', index)
        this.staticData && this.loadData()
      } else {
        this.$set(this.currentQueryParas, indexKey, index)
        this.$emit('update:queryParas', this.currentQueryParas)
        this.loadData()
      }
    },

    pageSizeChange (size) {
      const [indexKey, pageSizeKey] = this.pageKeys
      if (this.webPage) {
        this.$emit('pageSizeChange', size)
        this.staticData && this.$set(this.currentQueryParas, 'pageSize', size)
        this.staticData && this.$set(this.currentQueryParas, 'pageIndex', 1)
        this.staticData && this.loadData()
      } else {
        this.$set(this.currentQueryParas, pageSizeKey, size)
        this.currentQueryParas[indexKey] = 1
        this.$emit('update:queryParas', this.currentQueryParas)
        this.loadData()
      }
    }
  },
  created () {
    this.tableId = `sz_table_${getTableId()}`
  },
  render (createElement) {
    const {
      normalColumns,
      $scopedSlots,
      normalTableAttrs,
      tableDatas,
      showPage,
      paginationConfig,
      currentQueryParas,
      selectionChange,
      total,
      pageKeys,
      tableId,
      tableHeight,
      tableMaxHeight,
      borderBottom,
      $listeners
    } = this
    const [pageIndexKey, pageSizeKey] = pageKeys
    const paginationEvents = {
      'size-change': this.pageSizeChange,
      'current-change': this.pageIndexChange,
      // 'update:currentPage': (val) => {
      //   console.log('变了', val)
      // },
      'prev-click': this.pageIndexChange,
      'next-click': this.pageIndexChange
    }

    function renderColumns () {
      const renderHelper = function renderHelper (columns) {
        return columns.map((item, ids) => {
          const propName = item.prop
          if (item.childs) {
            return createElement(
              'el-table-column',
              {
                attrs: {
                  ...item
                }
              },
              renderHelper(item.childs)
            )
          } else if (item.type === 'index' || item.type === 'selection') {
            return createElement('el-table-column', {
              attrs: {
                ...item,
                fixed: 'left'
              }
            })
          } else {
            const propHeaderName = `${propName}Header`
            const defaultScopedSlots = {
              default: (scope) => {
                return $scopedSlots[propName]
                  ? $scopedSlots[propName](scope)
                  : item.formatter
                    ? item.formatter(scope.row)
                    : scope.row[propName]
              }
            }
            // 自定义表头
            if ($scopedSlots[propHeaderName]) {
              defaultScopedSlots.header = (scope) => {
                return $scopedSlots[propHeaderName](scope)
              }
            }
            return createElement('el-table-column', {
              attrs: {
                ...item,
                key: ids + (propName || '')
              },
              scopedSlots: defaultScopedSlots
            })
          }
        })
      }
      return renderHelper(normalColumns)
    }
    function createTableClss () {
      return borderBottom ? 'sz-table bordered' : 'sz-table'
    }
    // 表格渲染函数
    function renderTabel () {
      const attrs = {
        id: tableId,
        data: tableDatas,
        ...normalTableAttrs
      }

      if (tableHeight !== 0 && tableMaxHeight !== 0) {
        attrs.height = tableHeight
        attrs.maxHeight = tableMaxHeight
      }
      return createElement(
        'el-table',
        {
          class: createTableClss(),
          ref: 'sztable',
          attrs,
          on: {
            'selection-change' (arg) {
              selectionChange(arg)
            },
            ...$listeners
          }
        },
        renderColumns()
      )
    }

    function renderBottom () {
      const isSlot = !!$scopedSlots['footer-text']
      const redenCell = []
      if (isSlot) {
        redenCell.push($scopedSlots['footer-text']())
      }
      if (showPage) {
        redenCell.push(renderPagination())
      }

      return createElement(
        'div',
        {
          class: {
            flex: true,
            'flex-between': isSlot && showPage,
            'flex-end': showPage && !isSlot
          }
        },
        redenCell
      )
    }

    // 分页组件渲染
    function renderPagination () {
      return createElement('el-pagination', {
        class: 'sz-pagination',
        attrs: {
          ...defaultPagination,
          ...paginationConfig,
          'current-page': currentQueryParas[pageIndexKey],
          'page-size': currentQueryParas[pageSizeKey],
          total
        },
        on: paginationEvents
      })
    }

    return createElement('div', [renderTabel(), renderBottom()])
  }
}
</script>
<style lang="scss" src="./base-table.scss" scoped></style>
