<template>
  <div>
    <base-button
      label="上传附件"
      icon="iconfont iconshangchuan"
      btnType="upload"
      accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx"
      :http-request="function (param) {
          return uploadFile(param);
        }
      "
      v-if="showBtn"
    >
    </base-button>
    <div v-else class="tip">
      <div class="fileName" ref="filename">
        <el-tooltip
          effect="dark"
          :content="fileName"
          placement="top"
          :disabled="disabled"
          :enterable="false"
          v-if="showAll"
        >
        <div class="fileName">
          <div class="name" >{{ name }}</div>
          <span>{{ suffix }}</span>
        </div>
        </el-tooltip>
        <div style="white-space: nowrap;" v-else>{{ fileName }}</div>
      </div>
      <i class="iconfont iconshanchu2" @click="delfile"></i>
    </div>
  </div>
</template>
<script>
import baseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi } from '@/api/fileApi'
export default {
  components: { baseButton },
  data () {
    return {
      fileName: '',
      showBtn: true,
      name: '',
      suffix: '',
      disabled: false,
      showAll: false
    }
  },
  methods: {
    // 获取内容宽度
    // getwidth () {
    //   const dom = this.$refs.fileName
    //   this.disabled = dom.offsetWidth >= dom.scrollWidth
    // },
    // 上传附件
    uploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          this.showAll = false
          this.fileName = JSON.parse(JSON.stringify(param.file.name))
          const fileNameArr = param.file.name.split('.')
          this.suffix = '.' + fileNameArr.slice(-1)
          fileNameArr.pop()
          this.name = fileNameArr.join('.')
          if (this.fileName.length > 7) {
            this.showAll = true
          }
          this.showBtn = false
          this.success('文件上传成功')
          this.$emit('input', res.data.keyId)
          this.$emit('inputSuccess', res.data.fileName)
          this.$nextTick(() => {
            // this.getwidth()
          })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    delfile () {
      this.fileName = ''
      this.$emit('input', '')
      this.$emit('inputSuccess', '')
      this.showBtn = true
    }
  }
}
</script>
<style lang='scss' scoped>
.tip {
  font-size: 14px;
  color: #2862E7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fileName {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .name {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.iconfont {
  flex: 1;
  font-size: 18px;
  color: #f34145;
  display: inline-block;
  cursor: pointer;
}
</style>
