<template>
  <div>
    <!-- <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="退回提示"
  >

    <template slot="footer">

    </template>
  </base-dialog> -->
    <el-dialog
      title="退回提示"
      :visible.sync="visibleState"
      width="30%"
      :before-close="handleClose"
      class="backForm"
      :close-on-click-modal="false"
      center
    >
      <base-form

        :componentList="nodForm"
        :showBtns="false"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '120px'
        }"
        class="formStyle"
        ref="addfromdata"
      />
      <span slot="footer" class="dialog-footer">
        <base-button label="确 认" @click="submit" />
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { nodForm } from './confing'
import { contractApi } from '@/api/businessApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { BaseButton, BaseForm },
  props: {
    taskId: String,
    visible: Boolean,
    queryTaskResultDTO: Object
  },
  data () {
    return {
      options: [],
      addQueryParas: {}
    }
  },
  // 计算属性 类似于data概念
  computed: {
    nodForm () {
      return nodForm(this)
    },
    api () {
      return contractApi
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visible (val) {
      if (val) {
        this.addQueryParas = {}

        this.getNodeList()
        this.$nextTick(() => {
          this.$refs.addfromdata.clearValidate()
        })
      }
    }
  },
  // 方法集合
  methods: {
    // 选择节点
    changeReviewStatus (data) {
      if (data) {
        const [obj] = this.options.filter(item => item.taskDefinitionKey === data)
        this.queryTaskResultDTO.reviewStatus = obj.reviewType
        this.queryTaskResultDTO.taskDefinitionKey = obj.taskDefinitionKey
        this.queryTaskResultDTO.taskDefinitionName = obj.taskDefinitionName
      }
    },
    handleClose () {
      this.visibleState = false
    },
    // 查询回退节点
    getNodeList () {
      console.log(this.taskId, '22')
      this.api.getNodeList({ taskId: this.taskId }).then(res => {
        console.log(res.data)
        this.options = res.data
        this.$refs.addfromdata.$forceUpdate()
      })
    },
    // 提交
    submit () {
      this.$refs.addfromdata.validate(valid => {
        if (valid) {
          this.queryTaskResultDTO.businessId = this.queryTaskResultDTO.bizId
          this.queryTaskResultDTO.operationPost = '0'
          this.queryTaskResultDTO.reviewOpinion = ''
          this.queryTaskResultDTO.isfApproval = false
          delete this.queryTaskResultDTO.roleName
          this.api.examineCommit(this.queryTaskResultDTO).then(res => {
            if (res.data) {
              this.success('退回成功')
              this.handleClose()
              this.$emit('handleFilter')
            }
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.backForm {
  height: 100vh;
  overflow: hidden;
  /deep/.el-dialog {
    height: 300px;
    margin-top: 30vh !important ;
  }
  /deep/ .el-dialog__body {
    height: calc(100% - 160px);
  }
  /deep/ .el-dialog__header {
    background: #FFFFFF;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
}
</style>
