<template>
  <el-select
    v-bind="$attrs"
    v-on="currentListeners"
    style="width:100%"
    :allow-create="allowCreate"
    :default-first-option="defaultFirstOption"
    clearable
    :filter-method="filterMethod"
    ref='searchSelect'
  >
    <el-option
      v-for="v in currentOptions"
      :key="v[keyId]"
      :label="v[keyName]"
      :value="v[keyId]"
      :disabled="optionDisabled(v)"
    ></el-option>
  </el-select>
</template>

<script>
import { supplierApi } from '@/api/companyApi'
export default {
  name: 'supplierSelect',
  inheritAttrs: false,

  props: {
    allowCreate: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // options: {
    //   type: Array,
    //   default: () => []
    // },
    selectedField: {
      type: Array,
      default: () => ['id', 'name']
    },
    optionDisabled: {
      type: Function,
      default: () => {
        return false
      }
    },
    optionsFilter: {
      type: Function,
      default: null
    },
    max: { // 下拉框展示最大数量
      type: Number,
      default: 50
    }
  },
  data () {
    return {
      options: [],
      currentOptions: [],
      dom: ''
    }
  },
  computed: {
    currentListeners () {
      const _this = this
      return Object.assign(
        {},
        {
          ...this.$listeners,
          change: function (...arg) {
            _this.selfChange(...arg)
          }
        }
      )
    },
    // currentOptions () {
    //   if (typeof this.optionsFilter === 'function') {
    //     return this.optionsFilter(this.options)
    //   }
    //   return this.options
    // },
    keyName () {
      return this.selectedField[1] || 'name'
    },
    keyId () {
      return this.selectedField[0] || 'id'
    },
    defaultFirstOption () {
      return this.allowCreate
    }
  },
  watch: {
    '$attrs.value': {
      handler (val) {
        if (!val) {
          this.filterMethod(val)
        }
      },
      deep: true
    }
  },
  methods: {

    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.options = res.data
          this.filterMethod()
        }
      })
    },
    filterMethod (label) {
      let arr = []
      let options = this.filterDouble(this.options)
      if (typeof this.optionsFilter === 'function') {
        options = this.optionsFilter(options)
      }
      let max = this.max
      if (Array.isArray(this.$attrs.value)) {
        max = 999999999999999999999999999999999
      }
      arr = options.filter(item => item[this.selectedField[1]].includes(label))
      if (!label) { arr = options.slice(0, max) }
      this.currentOptions = arr.length > max ? arr.slice(0, max) : arr
    },
    /**
     * 过滤重复的key属性
     */
    filterDouble (data = []) {
      const [key] = this.selectedField
      const res = []
      const empty = Object.create(null)
      data.forEach((it) => {
        const keyName = it[key]
        if (!empty[keyName]) {
          res.push(it)
          empty[keyName] = true
        }
      })
      return res
    },
    selfChange (val) {
      const {
        options,
        keyId,
        $attrs: { multiple }
      } = this
      const ids = multiple ? val : [val]
      const curSelected = options.filter(it => ids.indexOf(it[keyId]) > -1)
      let row
      if (multiple) {
        // 多选
        row = curSelected
      } else {
        row = curSelected.length ? curSelected[0] : {}
      }
      this.$emit('change', val, row, this.$attrs.keyIndex)
    }
  },
  mounted () {
    this.gysList()
    this.dom = this.$refs.searchSelect.$children[0]
    this.dom.$el.children[0].setAttribute('maxlength', 50)
  }
}
</script>
