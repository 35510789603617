import store from '@/store/index'
/**
 * 格式化字 典
 * @param {String} dictTypeId 字典类型
 * @return {String} 结果
 */
export function getDictLists (dictTypeId) {
  const dictMaps = store.state.sys.dictMaps
  return dictMaps[dictTypeId] || []
}
/**
 * 获取字典项
 * @param {String} dictTypeId 字典类型
 * @param {String} 字典Id
 * @return {String} 结果
 */
export function getDict (dictTypeId, dictId) {
  const dictMaps = store.state.sys.dictMaps
  const dictList = dictMaps[dictTypeId]
  return dictList.find(it => it.dictId === dictId)?.dictName
}

/**
 * 获取对应字典类型的
 * @param { String } dictTypeId
 * @returns dictId map
 */
export const getDictIdMaps = (dictTypeId) => {
  const dictMaps = store.state.sys.dictMaps
  const maps = Object.create(null)
  const list = dictMaps[dictTypeId]
  if (list && list.length) {
    list.forEach(it => {
      const { dictId, dictName } = it
      maps[dictId] = dictName
    })
  }
  return maps
}
/**
 * 获取对应字典类型的 dictName map
 * @param {*} dictTypeId
 * @returns
 */
export const getDictNameMaps = (dictTypeId) => {
  const dictMaps = store.state.sys.dictMaps
  const maps = Object.create(null)
  const list = dictMaps[dictTypeId]
  if (list && list.length) {
    list.forEach(it => {
      const { dictId, dictName } = it
      maps[dictName] = dictId
    })
  }
  return maps
}

/**
 * 格式多选
 * @param {String} dictTypeId 字典类型
 * @param {String} dictIdString 字典Id
 * @param {String} type 返回类型 可选 String Arr
 * @return {String,Arr} 结果
 */
export const filterMultiple = (dictTypeId, dictIdString, type) => {
  const dictIdsArr = dictIdString.split(',')
  const outArr = []
  dictIdsArr.forEach(item => {
    const dictMaps = store.state.sys.dictMaps[dictTypeId]
    const [addArr] = dictMaps.filter(ele => ele.dictId === item)
    const addName = addArr ? addArr.dictName : ''
    outArr.push(addName)
  })
  if (type === 'Arr') {
    return outArr
  } else if (type === 'String') {
    return outArr.join(',')
  }
}
