import Company from './company/company'

const SUB_SYSTEM = 'company'
// 供应商管理
export const supplierApi = new Company(SUB_SYSTEM, 'supplier')
export const supplierApiPage = new Company(SUB_SYSTEM, 'supplier/personnel')
export const supplierFileApi = new Company(SUB_SYSTEM, 'supplier/file')

// 企业变更信息
export const supplierChange = new Company(SUB_SYSTEM, 'supplier/change')
// 限制消费令
export const supplierLimitConsumption = new Company(SUB_SYSTEM, 'supplier/limitConsumption')
// 被执行人信息
export const supplierExecutor = new Company(SUB_SYSTEM, 'supplier/executor')

// 认证管理
export const certifyApi = new Company(SUB_SYSTEM, 'certify')
// 人员信息
export const personnelApi = new Company(SUB_SYSTEM, 'personnel')
// 二维码
export const qrCodeApi = new Company(SUB_SYSTEM, 'qrCode')

// 财务信息
export const analysisApi = new Company(SUB_SYSTEM, 'analysis')

// 诉讼信息
export const litigationApi = new Company(SUB_SYSTEM, 'litigation')

// 舆情信息
export const opinionApi = new Company(SUB_SYSTEM, 'opinion')
