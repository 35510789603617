<template>
<!-- 要素落实管理列表 -->
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="fromConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    ><div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold" />
    </div></base-form>
  <!-- 数据表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :type="type"
      ref="tableData"
      :api="api"
      :getApi="'getPage'"
      :loadCount="loadCount"
      :queryParas.sync="queryParas"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button content="查看" icon="iconfont iconchakan" @click="info(scope.row)" v-if="scope.row.reviewStatus === 10||scope.row.reviewStatus === 20||scope.row.reviewStatus === 30"/>
        <icon-button
          @click.prevent="
            jump('/business/essentialFactor/elementProduction', scope.row)
          "
          content="编辑"
          v-if="(scope.row.reviewStatus === 40||scope.row.reviewStatus === 50||scope.row.fileMakeStatus=== 10 || scope.row.fileMakeStatus=== 20) && scope.row.reviewStatus !==91
          "
          icon="iconfont iconbianji3"
        />
         <!-- <icon-button
          @click.prevent="cancelTask(scope.row)"
          content="撤回"
          icon="iconfont iconchehui"
          v-if="scope.row.fileMakeStatus === 30&&scope.row.reviewStatus === 10"
        /> -->
        <!-- <icon-button
          @click.prevent="back(scope.row)"
          content="退回"
          icon="iconfont icona-tuihuipiliangtuihui"
           v-if="scope.row.fileMakeStatus === 30&&scope.row.reviewStatus === 40"
        /> -->
         <!-- v-if="scope.row.reviewStatus===50||scope.row.reviewStatus===40||scope.row.reviewStatus===0||scope.row.reviewStatus===60||scope.row.fileMakeStatus===10||scope.row.fileMakeStatus===20" -->
      </template>
    </base-table>
     <back-dialog :visible.sync="visible" :queryTaskResultDTO='queryTaskResultDTO' :taskId='taskId' @handleFilter='handleFilter' />
  </div>
</template>
<script>
import BackDialog from '@/pages/business/components/back-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { fromConfig, columnsConfig } from './utils/manage.config.js'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { progressApi } from '@/api/businessApi'
import Storage from '@/utils/storage'
export default {
  name: 'manage',
  components: { BaseForm, BaseTable, IconButton, BaseButton, BackDialog },
  data () {
    return {
      visible: false,
      taskId: '',
      queryTaskResultDTO: {},
      fold: false,
      type: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'ELEMENT_IMPLEMENTATION'
      },
      loadCount: 0,
      tableData: [],
      list: []
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    fromConfig () {
      return fromConfig(this)
    },
    api () {
      return progressApi
    }
  },
  mounted () {
    this.handleFilter()
    this.getList()
  },

  methods: {
    // 退回按钮显示
    backShow (row) {

    },

    // 退回
    back (row) {
      if (row.queryTaskResultDTO && row.queryTaskResultDTO.taskId) {
        const params = {
          businessId: row.queryTaskResultDTO.bizId,
          instanceId: row.queryTaskResultDTO.instanceId,
          taskId: row.queryTaskResultDTO.taskId,
          processName: row.processStatusId
        }

        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.queryTaskResultDTO = row.queryTaskResultDTO
            this.queryTaskResultDTO.processStatus = this.queryParas.processStatus
            this.taskId = row.queryTaskResultDTO.taskId
            this.visible = true
          }
        })
      } else {
        this.warning('数据错误')
      }
    },
    // 按钮显示
    eidtShow (reviewStatus) {
      const list = [10, 20, 40, 50]
      const flag = list.some((val) => {
        return val === reviewStatus
      })
      return flag
    },
    // 撤回
    cancelTask (row) {
      const data = {
        businessId: row.keyId,
        processStatus: 'ELEMENT_IMPLEMENTATION'
      }
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.api.cancel(data).then((res) => {
            if (res.data) {
              this.success('撤回成功')
            }
            this.handleFilter()
          })
        })
        .catch(() => {})
    },
    // 获取供应商
    getList () {
      this.api
        .getDrop()
        .then((res) => {
          this.list = res.data
        })
        .catch((error) => {
          console.log(error)
        })
    },

    jump (url, row) {
      this.$router.push({ path: url, query: { keyId: row.keyId, instanceId: row.instanceId } })
      Storage.setSession('row', row)
    },
    info (row) {
      this.$router.push({ path: '/business/essentialFactor/ImplementAudit', query: { type: 'V', showStep: 'show' } })
      Storage.setSession('examine', row)
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'ELEMENT_IMPLEMENTATION'
      }
    },
    // 查询
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
